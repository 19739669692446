import { useLocation, useNavigate } from 'react-router';

export type AppRoute = (typeof NavigatorService.AppRoutes)[keyof typeof NavigatorService.AppRoutes];

class NavigatorService {
    static AppRoutes = {
        FEED: '/',
        PLAYLIST: '/playlist',
    };

    static LANGUAGE_PARAM = 'lng';
    private redirect: ReturnType<typeof useNavigate>;
    private location: typeof useLocation;
    // private logsService: LogsService;

    constructor(
        redirect: ReturnType<typeof useNavigate>,
        location: typeof useLocation,
    ) {
        this.redirect = redirect;
        this.location = location;
    }

    public openChannel = (username: string): void => {
        this.redirect(`${NavigatorService.AppRoutes.PLAYLIST}/${username}`);
    };

    // как будто роутинг не всрался и его можно удалить
    public getChannelUsername = (): string => {
        return this.location().pathname.split('/')[2];
    };

    public getMiniAppStartupParam = (): [string, number] | null => {
        const params = new URLSearchParams(window.Telegram?.WebApp.initData);

        const param = params.get('start_param');
        if (!param) {
            return null;
        }

        const [playlist, postToPlay] = param.split('-');

        if (postToPlay) {
            return [playlist, parseInt(postToPlay, 10 || '0')];
        }

        return [playlist, 0];
    };

    public goMainPage = (): void => {
        this.redirect(NavigatorService.AppRoutes.FEED);
    };
}

export default NavigatorService;
