import { Avatar, Spinner } from '@telegram-apps/telegram-ui';
import React, { FC, useEffect, useRef } from 'react';

import ConfigurationService from '@/services/configuration-service';
import { Channel } from '@/services/post-cast-service';
import Cell from '@/shared/components/cell';
import Tgico from '@/shared/icons/tgico';

import * as UI from './ui';


type FeedPageProps = {
    feedLoading: boolean;
    channels: Channel[];
    configurationService: ConfigurationService;
    onLoadMore: VoidFunction;
    onChannelClick: (username: string) => void;
};

const FeedPage: FC<FeedPageProps> = ({
    feedLoading,
    channels,
    configurationService,
    onLoadMore,
    onChannelClick,
}) => {
    const fetchBar = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        onLoadMore();
                    }
                });
            },
            {
                root: null,
                threshold: 0.1,
            },
        );

        if (fetchBar.current) {
            observer.observe(fetchBar.current);
        }

        return () => {
            if (fetchBar.current) {
                observer.unobserve(fetchBar.current);
            }
        };
    }, [fetchBar]);

    return (
        <UI.Wrapper>
            <UI.List>
                {
                    channels.map((channel: Channel) => (
                        <React.Fragment key={channel.username}>
                            <Cell
                                key={channel.title}
                                before={
                                    <Avatar
                                        size={48}
                                        src={configurationService.image(channel.photo)}
                                    />
                                }
                                head={channel.title}
                                body={channel.description}
                                after={
                                    channel.score != -1 ?
                                        <Tgico
                                            inline
                                            type='pin'
                                            size={1}
                                        /> : undefined
                                }
                                onClick={() => onChannelClick(channel.username)}
                            />
                        </React.Fragment>
                    ))
                }

                <UI.FetchBar ref={fetchBar} />
            </UI.List>

            {
                feedLoading && (
                    <UI.SpinnerWrapper>
                        <Spinner size='m' />
                    </UI.SpinnerWrapper>
                )
            }

        </UI.Wrapper>
    );
};

export default FeedPage;
