import styled from 'styled-components';

import { FontSmooth } from '@/shared/typography';

export const Wrapper = styled.div<{ $inline?: boolean; size: number }>`
    text-align: center;
    font-size: ${props => props.size}rem;
    line-height: 1;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: ${props => props.$inline ? 'inherit' : '.5rem'};
    position: relative;
    transition: color .15s ease-in-out,opacity .15s ease-in-out;
    display: ${props => props.$inline ? 'inline' : 'flex'};
    align-items: center;
    justify-content: center;
    user-select: none;
  
    :active {
      background-color: ${props => props.theme.colors.lightPrimaryColor};
    }
  
  ${FontSmooth}
`;

export const Inner = styled.span`
  border-radius: 50%;
  
  font-family: tgico!important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: ${props => props.theme.colors.primaryColor};
`;
