export const voiceFromPlaylist = (
    selectedVoice: string,
    playlist: {
        audio: Record<string, { filename: string, duration: number }>
    }[]
): string => {
    const voices = playlist.reduce((uniqVoices, post) => {
        Object.keys(post.audio || {}).forEach(voice => {
            uniqVoices.add(voice);
        });

        return uniqVoices;
    }, new Set<string>());

    if (voices.has(selectedVoice)) {
        return selectedVoice;
    }

    return Array.from(voices)[0];
};

export const getAnyDuration = (audio: Record<string, { filename: string, duration: number }>) => {
    return audio[Object.keys(audio)[0]].duration;
};
