import styled from 'styled-components';

import { Header2Bold } from '@/shared/typography';

export const Wrapper = styled.div`
  padding: 0 12px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  height: 72px;
  overflow: hidden;
  cursor: pointer;
  
  &:hover {
    background-color: var(--tgui--tertiary_bg_color);
  }
`;

export const Left = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline-start: 12px;
  white-space: nowrap;
  padding: 14px 0;
  text-overflow: ellipsis; //  todo dubicate
  overflow: hidden;
`;

export const Head = styled.span`
  ${Header2Bold};
  text-overflow: ellipsis; //  todo dubicate
  overflow: hidden;
`;

export const Body = styled.span`
  margin-top: .125rem;
  white-space: nowrap;
  color: ${props => props.theme.colors.secondaryTextColor};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Before = styled.div`
  display: flex;
  align-items: center;
`;

export const After = styled.div`
  display: flex;
  align-items: center;
`;

