import { modulo } from '@/lib/math';
import EventEmitter from '@/lib/subscriber';

class AudioService extends EventEmitter<{
    'onProgress': number;
    'onPlay': void;
    'onPause': void;
    'onEnded': void;
    'onChange': number;
    'onTimeUpdate': number;
    'onPlaybackRateChange': number;
}>{
    static REWIND_STEP = 10000;

    public audio: HTMLAudioElement;
    private playing: boolean;

    public current: number;
    private playlist: string[];

    constructor(
        current: number,
        playlist: string[],
        time = 0,
        playbackRate = 1,
    ) {
        super();

        this.audio = new Audio(playlist[current]);
        this.audio.currentTime = time;
        this.audio.playbackRate = playbackRate;

        this.current = current;
        this.playlist = playlist;
        this.playing = false;

        // https://stackoverflow.com/questions/18266437/html5-video-currenttime-not-setting-properly-on-iphone
        if (window.Telegram?.WebApp.platform === 'ios') {
            this.audio.load();
        }

        this.audio.addEventListener('ratechange', () => {
            this.dispatch('onPlaybackRateChange', this.audio.playbackRate);
        });

        this.audio.addEventListener('play', () => {
            this.dispatch('onPlay', undefined);
            this.playing = true;
        });

        this.audio.addEventListener('pause', () => {
            this.dispatch('onPause', undefined);
            this.playing = false;
        });

        this.audio.addEventListener('timeupdate', () => {
            this.dispatch('onProgress', (this.audio.currentTime / this.audio.duration) * 100 || 0);
        });

        this.audio.addEventListener('ended', () => {
            this.dispatch('onEnded', undefined);
            this.playNext();
        });

        this.audio.addEventListener('timeupdate', () => {
            this.dispatch('onTimeUpdate', this.audio.currentTime);
        });
    }

    public pause = () => {
        this.audio.pause();
    };

    public setPlaylist = (playlist: string[]) => {
        this.playlist = playlist;
    };

    public play = (time = 0) => {
        const previousPlaybackRate = this.audio.playbackRate;
        this.audio.src = this.playlist[this.current];
        this.audio.playbackRate = previousPlaybackRate;
        this.audio.currentTime = time;
        this.audio.play();
    };

    public playNext = () => {
        this.current = modulo(this.current + 1, this.playlist.length);
        this.play();
        this.dispatch('onChange', this.current);
    };

    public playPrevious = () => {
        this.current = modulo(this.current - 1, this.playlist.length);
        this.play();
        this.dispatch('onChange', this.current);
    };

    public playIndex = (index: number, time = 0) => {
        this.current = index;
        this.play(time);
        this.dispatch('onChange', this.current);
    };

    public togglePlay = () => {
        if (this.playing) {
            this.audio.pause();
        } else {
            this.audio.play();
        }
    };

    public onPlay = (callback: VoidFunction) => {
        this.addEventListener('onPlay', callback);
    };

    public onPause = (callback: VoidFunction) => {
        this.addEventListener('onPause', callback);
    };

    public onProgress = (callback: (progress: number) => void) => {
        this.addEventListener('onProgress', callback);
    };

    public onChange = (callback: (index: number) => void) => {
        this.addEventListener('onChange', () => callback(this.current));
    };

    public onTimeUpdate = (callback: (seconds: number) => void) => {
        this.addEventListener('onTimeUpdate', callback);
    };

    public onPlaybackRateChange = (callback: (playbackRate: number) => void) => {
        this.addEventListener('onPlaybackRateChange', callback);
    };

    public getDuration = (): number => {
        if (isNaN(this.audio.duration)) {
            return 0;
        }

        return this.audio.duration;
    };

    public increasePlaybackRate = () => {
        if (this.audio.playbackRate === 2) {
            this.audio.playbackRate = .5;

            return;
        }

        this.audio.playbackRate = this.audio.playbackRate + .25;
    };

    public rewindForward = (): void => {
        this.setJumpTime(AudioService.REWIND_STEP);
    };

    public rewindBack = (): void => {
        this.setJumpTime(-AudioService.REWIND_STEP);
    };

    public setJumpTime = (time: number): void => {
        const audio = this.audio;
        const { duration, currentTime: prevTime } = audio;
        if (audio.readyState === audio.HAVE_NOTHING ||
            audio.readyState === audio.HAVE_METADATA ||
            !isFinite(duration) ||
            !isFinite(prevTime)
        ) {
            return;
        }

        let currentTime = prevTime + time / 1000;
        if (currentTime < 0) {
            audio.currentTime = 0;
            currentTime = 0;
        } else if (currentTime > duration) {
            audio.currentTime = duration;
            currentTime = duration;
        } else {
            audio.currentTime = currentTime;
        }
    };
}

export default AudioService;
