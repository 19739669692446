type EventMap = Record<string, any>;

type EventKey<T extends EventMap> = string & keyof T;
type EventReceiver<T> = (data: T) => void;

class EventEmitter<T extends EventMap> {
    private listeners: {
        [K in keyof EventMap]?: ((p: EventMap[K]) => void)[];
    } = {};

    public addEventListener<K extends EventKey<T>>(event: K, listener: EventReceiver<T[K]>): void {
        this.listeners[event] = (this.listeners[event] || []).concat(listener);
    }

    public removeEventListener<K extends EventKey<T>>(event: K, listener: EventReceiver<T[K]>): void {
        this.listeners[event] = (this.listeners[event] || []).filter(f => f !== listener);
    }
    public dispatch<K extends EventKey<T>>(event: K, data: T[K]): void {
        (this.listeners[event] || []).forEach((listener) => {
            listener(data);
        });
    }
}

export default EventEmitter;
