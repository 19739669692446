import {
    Button,
    Modal,
    Snackbar,
} from '@telegram-apps/telegram-ui';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioPlayer from '@/features/AudioPlayer/src';
import MiniPlayer from '@/features/MiniPlayer';
import { modulo } from '@/lib/math';
import Tgico from '@/shared/icons/tgico';


import * as UI from './ui';

type PlayerProps = {
    audio: HTMLAudioElement;
    title: string;
    description: string;
    playlist: string;
    image: string;
    playing: boolean;
    voices: string[];
    selectedVoice: string;
    playbackRate: number;
    togglePlay: VoidFunction;
    playNext: VoidFunction;
    playPrevious: VoidFunction;
    rewindForward: VoidFunction;
    rewindBack: VoidFunction;
    onVoiceChange: (voice: string) => void;
    increasePlaybackRate: VoidFunction;
    showLargePlayer: boolean;
    onShowLargePlayer: (open: boolean) => void;
    onAuthorClick: VoidFunction;
    onContentClick: VoidFunction;
    onShareClick: (username: string) => void;
}

const Player: FC<PlayerProps> = ({
    audio,
    title,
    description,
    playlist,
    image,
    playing,
    voices,
    selectedVoice,
    playbackRate,
    togglePlay,
    playNext,
    playPrevious,
    rewindForward,
    rewindBack,
    onVoiceChange,
    increasePlaybackRate,
    onShowLargePlayer,
    showLargePlayer,
    onAuthorClick,
    onContentClick,
    onShareClick,
}) => {
    const { t } = useTranslation();
    const [isCopiedSnackbarShown, setIsCopiedSnackbarShown] = useState(false);

    const handleVoiceClick = useCallback(() => {
        if (voices.length <= 1) {
            return;
        }

        const currentIndex = voices.indexOf(selectedVoice);
        onVoiceChange(voices[modulo(currentIndex + 1, voices.length)]);
    }, [selectedVoice, voices]);

    const handleShareClick = useCallback(() => {
        onShareClick(playlist);
        onShowLargePlayer(false);
        setIsCopiedSnackbarShown(true);
    }, []);

    return (
        <>
            <MiniPlayer
                title={title}
                image={image}
                onClick={() => onShowLargePlayer(true)}
                playing={playing}
                onTogglePlay={togglePlay}
                onRewindForward={rewindForward}
            />

            <Modal
                open={showLargePlayer}
                onOpenChange={onShowLargePlayer}
                preventScrollRestoration
            >
                <UI.ModalContent>
                    <UI.Header>
                        <UI.SwipeDown onClick={() => onShowLargePlayer(false)}>
                            <Tgico
                                inline
                                type='down'
                                size={2}
                            />
                        </UI.SwipeDown>

                        <UI.Title onClick={onAuthorClick}>
                            {playlist}
                        </UI.Title>

                        <UI.ShareLink onClick={handleShareClick}>
                            <Tgico
                                inline
                                size={1.5}
                                type='share'
                            />
                        </UI.ShareLink>
                    </UI.Header>

                    <UI.PlayerCover>
                        <UI.Image src={image} />
                    </UI.PlayerCover>

                    <UI.PlayerContent>
                        <AudioPlayer
                            audio={audio}
                            autoPlay
                            onClickPrevious={playPrevious}
                            onClickNext={playNext}
                            onRewindForward={rewindForward}
                            onRewindBack={rewindBack}
                        >
                            <UI.AdditionalControl>
                                <UI.PlaybackRate onClick={increasePlaybackRate}>
                                    {playbackRate}

                                    <Tgico
                                        inline
                                        size={1.5}
                                        type='cross'
                                    />
                                </UI.PlaybackRate>

                                <Button
                                    before={
                                        <Tgico
                                            size={1.5}
                                            type='person'
                                        />
                                    }
                                    mode='gray'
                                    size='s'
                                    onClick={handleVoiceClick}
                                >
                                    {selectedVoice}
                                </Button>
                            </UI.AdditionalControl>
                        </AudioPlayer>
                    </UI.PlayerContent>

                    <UI.ContentText onClick={onContentClick}>
                        {description}
                    </UI.ContentText>
                </UI.ModalContent>
            </Modal>

            {
                isCopiedSnackbarShown && (
                    <Snackbar onClose={() => setIsCopiedSnackbarShown(false)}>
                        {t('features.Player.linkCopiedCaption')}
                    </Snackbar>
                )
            }
        </>
    );
};

export default Player;
