import copy from 'copy-to-clipboard';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Player from '@/features/Player';
import { throttle } from '@/lib/google-closure/functions';
import AudioService from '@/services/audio-service';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import PostCastService, {
    Channel,
    InitData, Post,
    Settings
} from '@/services/post-cast-service';
import { PageContent } from '@/shared/ui/layout/page-content';

import { voiceFromPlaylist } from '../../domain/voice';

import FeedPage from './pages/feed-page';
import PlaylistPage from './pages/playlist-page';

export type AppData = {
    services: {
        postCastService: PostCastService;
        audioService: AudioService;
        navigatorService: NavigatorService;
        configurationService: ConfigurationService;
    };
    posts: Post[];
    currentPost: number;
    currentTime: number;
    playing: boolean;
    playerPlaylist: string;
    onPostClick: (playlist: string, index: number) => void;
    playlistLoading: boolean;
};

type SpaRouterProps = {
    displayedPlaylist: string;
    initData: InitData;
    postCastService: PostCastService;
    navigatorService: NavigatorService;
    logsService: LogsService;
    configurationService: ConfigurationService;
};

type SpaRouterState = {
    feedLoadedCompletely: boolean;
    feedLoading: boolean;
    playlistLoading: boolean;
    playing: boolean;
    currentTime: number;
    currentPostId: number;
    playerPlaylist: string;
    playbackRate: number;
    selectedVoice: string;
    showLargePlayer: boolean;
    channelPagination: number;
}

class PlayerApp extends React.Component<SpaRouterProps, SpaRouterState> {
    private audioService: AudioService;
    private channelsMap: Record<string, Channel>;
    private postMap: Record<string, Post[]>;
    private channels: Channel[];

    constructor(props: SpaRouterProps) {
        super(props);

        const { initData } = props;

        const {
            playlistToPlay,
            currentPost,
            selectedVoice,
            time,
            playbackRate,
            posts,
            // playImmediately,
            // showChannelPage,
        } = initData.channelToOpen;

        this.channelsMap = initData.channelsMap;
        this.channels = initData.channels;
        this.postMap = initData.postsMap;

        const audioService = new AudioService(
            this.getPostIndexById(playlistToPlay, currentPost),
            this.getAudioPlaylist(
                posts,
                selectedVoice,
            ),
            time,
            playbackRate,
        );

        this.audioService = audioService;

        this.state = {
            feedLoadedCompletely: false,
            feedLoading: false,
            playlistLoading: false,
            playing: false,
            currentTime: time,
            currentPostId: currentPost,
            playerPlaylist: playlistToPlay,
            playbackRate,
            selectedVoice,
            showLargePlayer: initData.channelToOpen.playImmediately,
            channelPagination: 1,
        };

        audioService.onChange(this.handleAudioChange);
        audioService.onPlay(this.handlePlay);
        audioService.onPause(this.handlePause);
        audioService.onTimeUpdate(this.handleTimeUpdate);
        audioService.onPlaybackRateChange(this.handlePlaybackRateChange);

        Telegram.WebApp.BackButton.onClick(() => {
            props.navigatorService.goMainPage();

            Telegram.WebApp.BackButton.hide();
        });

        // if (initData.channelToOpen.playImmediately) {
        //     audioService.play();
        // }
    }

    private handleAudioChange = (index: number) => {
        const { playerPlaylist } = this.state;

        const currentPostId = this.getPostIdByIndex(playerPlaylist, index);
        this.setState({
            currentPostId,
        });

        const { settings } = this.props.initData;

        if (this.postMap[playerPlaylist].length - settings.playlistPreloadOffset - index <= 0) {
            this.loadPlaylist(playerPlaylist);
        }
    };

    private handlePlay = () => {
        this.props.postCastService.event({
            eventName: 'user_postcast_started',
        });

        this.setState({
            playing: true,
        });
    };

    private handlePause = () => {
        this.setState({
            playing: false,
        });
    };

    private handleTimeUpdate = (currentTime: number) => {
        this.setState({
            currentTime,
        });

        if (currentTime !== 0 && currentTime !== this.state.currentTime) {
            this.updateSettings({
                lastPlaylist: this.state.playerPlaylist,
                lastPostId: this.state.currentPostId,
                time: currentTime,
                selectedVoice: this.state.selectedVoice,
                playbackRate: this.state.playbackRate,
            });
        }
    };

    private handlePlaybackRateChange = (playbackRate: number) => {
        this.setState({
            playbackRate,
        });
    };

    private updateSettings = throttle((settings: Settings) => {
        this.props.postCastService.updateSettings(settings);
    }, 5 * 1000);

    private getPostIndexById = (playlist: string, id?: number) => {
        if (!id) {
            return 0;
        }

        for (let i = 0; i < this.postMap[playlist].length; i++) {
            if (this.postMap[playlist][i].id === id) {
                return i;
            }
        }

        return 0;
    };

    private getPostIdByIndex = (playlist: string, index: number) => {
        return this.postMap[playlist][index].id;
    };

    private getAudioPlaylist = (posts: Post[], voice: string) => {
        return posts.map(post => {
            if (post.audio[voice]) {
                return this.props.configurationService.audio(post.audio[voice].filename);
            }

            if (post.audio.novoice) {
                return this.props.configurationService.audio(post.audio['novoice'].filename);
            }

            const firstVoice = Object.keys(post.audio)[0];

            if (firstVoice) {
                return this.props.configurationService.audio(post.audio[firstVoice].filename);
            }

            return this.props.configurationService.audio(post.audio['novoice']);
        });
    };

    private loadPlaylist = async (
        username: string,
    ) => {
        if (this.state.playlistLoading) {
            return;
        }

        // const loadedAmount = channel.playlist.length;
        // if (loadedAmount >= channel.total) {
        //     return;
        // }

        this.setState({
            playlistLoading: true,
        });


        const posts = this.postMap[username];
        const pagination = posts.length / this.props.initData.settings.paginationPlaylistPageSize;
        const morePosts = await this.props.postCastService.morePosts(username, pagination);

        for (let i = 0; i < morePosts.length; i++) {
            this.postMap[username].push(morePosts[i]);
        }

        this.audioService.setPlaylist(
            this.getAudioPlaylist(
                this.postMap[username],
                this.state.selectedVoice
            )
        );

        this.setState({
            playlistLoading: false,
        });
    };

    private loadChannels = async () => {
        if (this.state.feedLoadedCompletely || this.state.feedLoading) {
            return;
        }

        const moreChannelsFromFeed = await this.props.postCastService.moreChannels(
            this.state.channelPagination + 1
        );

        this.setState({
            feedLoading: true,
            channelPagination: this.state.channelPagination + 1,
        });

        /** Handle required channels to avoid dublicates */
        for (let i = 0; i < moreChannelsFromFeed.channels.length; i++) {
            const channel = moreChannelsFromFeed.channels[i];
            const alreadyInFeed = this.channelsMap[channel.username];
            if (!alreadyInFeed) {
                this.channels.push(channel);
                this.channelsMap[channel.username] = channel;
            }
        }

        this.setState({
            feedLoading: false,
        });

        if (moreChannelsFromFeed.isOver) {
            this.props.postCastService.event({
                eventName: 'feed_is_over',
            });
            this.setState({
                feedLoadedCompletely: true,
            });
        }
    };

    private handleChannelClick = async (username: string) => {
        if (!this.postMap[username]) {
            // todo loader
            const morePosts = await this.props.postCastService.morePosts(username, 0);
            const posts = [];

            for (let i = 0; i < morePosts.length; i++) {
                posts.push(morePosts[i]);
            }

            this.audioService.setPlaylist(
                this.getAudioPlaylist(
                    posts,
                    this.state.selectedVoice
                )
            );

            this.postMap[username] = posts;
            this.forceUpdate();
        }

        this.props.navigatorService.openChannel(username);
    };

    private onPostClick = (playlistName: string, index: number) => {
        if (this.state.playerPlaylist === playlistName && index === this.audioService.current) {
            this.setState({
                showLargePlayer: true,
            });

            return;
        }

        if (this.state.playerPlaylist !== playlistName) {
            const voice = voiceFromPlaylist(this.state.selectedVoice, this.postMap[playlistName]);

            this.setState({
                playerPlaylist: playlistName,
                selectedVoice: voice,
            });

            // todo DRY
            this.audioService.pause();
            this.audioService.setPlaylist(
                this.getAudioPlaylist(
                    this.postMap[playlistName],
                    voice,
                )
            );
        }

        this.audioService.playIndex(index);
    };

    private handleVoiceChange = (voice: string) => {
        this.setState({
            selectedVoice: voice,
        });

        this.audioService.pause();
        this.audioService.setPlaylist(
            this.getAudioPlaylist(
                this.postMap[this.state.playerPlaylist],
                voice,
            )
        );
        this.audioService.playIndex(this.audioService.current, this.state.currentTime);
    };

    private handleShowLargePlayer = (showLargePlayer: boolean) => {
        this.setState({
            showLargePlayer,
        });
    };

    private handleShareClick = (username: string) => {
        const { telegramBotPublicName, telegramBotAppName } = this.props.initData.settings;
        const { currentPostId } = this.state;

        copy(`https://t.me/${telegramBotPublicName}/${telegramBotAppName}?startapp=${username}-${currentPostId}`);
    };

    public render() {
        const currentPostIndex = this.getPostIndexById(this.state.playerPlaylist, this.state.currentPostId);
        const postToPlay = this.postMap[this.state.playerPlaylist][currentPostIndex];

        return (
            <>
                <PageContent>
                    <Routes>
                        <Route
                            path={NavigatorService.AppRoutes.FEED}
                            element={
                                <FeedPage
                                    feedLoading={this.state.feedLoading}
                                    channels={this.channels}
                                    configurationService={this.props.configurationService}
                                    onLoadMore={this.loadChannels}
                                    onChannelClick={this.handleChannelClick}
                                />
                            }
                        />

                        <Route
                            path={`${NavigatorService.AppRoutes.PLAYLIST}/:username`}
                            element={
                                <PlaylistPage
                                    posts={
                                        this.props.displayedPlaylist ?
                                            this.postMap[this.props.displayedPlaylist] : []
                                    }
                                    currentPost={currentPostIndex}
                                    currentTime={this.state.currentTime}
                                    playing={this.state.playing}
                                    playerPlaylist={this.state.playerPlaylist}
                                    playlistLoading={this.state.playlistLoading}
                                    onPostClick={this.onPostClick}
                                    navigatorService={this.props.navigatorService}
                                    onPlaylistLoad={this.loadPlaylist}
                                />
                            }
                        />
                    </Routes>
                </PageContent>

                <Player
                    audio={this.audioService.audio}
                    title={postToPlay.title}
                    description={postToPlay.content}
                    playlist={this.state.playerPlaylist}
                    image={this.props.configurationService.image(postToPlay.image)}
                    playing={this.state.playing}
                    voices={postToPlay.audio ? Object.keys(postToPlay.audio) : []}
                    selectedVoice={this.state.selectedVoice}
                    playbackRate={this.state.playbackRate}
                    togglePlay={this.audioService.togglePlay}
                    playNext={this.audioService.playNext}
                    playPrevious={this.audioService.playPrevious}
                    rewindForward={this.audioService.rewindForward}
                    rewindBack={this.audioService.rewindBack}
                    onVoiceChange={this.handleVoiceChange}
                    increasePlaybackRate={this.audioService.increasePlaybackRate}
                    onShowLargePlayer={this.handleShowLargePlayer}
                    showLargePlayer={this.state.showLargePlayer}
                    onAuthorClick={
                        () => Telegram.WebApp.openTelegramLink(this.channelsMap[this.state.playerPlaylist].link)
                    }
                    onContentClick={() => Telegram.WebApp.openTelegramLink(postToPlay.link)}
                    onShareClick={this.handleShareClick}
                />
            </>
        );
    }
}

export default PlayerApp;
