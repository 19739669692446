import { css } from 'styled-components';

export const FontSmooth = css`
    font-family: var(--tgui--font-family);
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
`;

const FontBold = css`
  font-weight: 600;;
`;

const FontMedium = css`
  font-weight: 500;;
`;

export const Header1Bold = css`
    font-size: 24px;

    ${FontBold};
    ${FontSmooth};
`;

export const Header2Bold = css`
    font-size: 16px;
    line-height: 1.375rem;
  
    ${FontMedium};
    ${FontSmooth};
`;
