// @ts-ignore
export const throttle = function(f, interval, opt_scope?: any) {
    'use strict';
    let timeout = 0;
    let shouldFire = false;
    // @ts-ignore
    let storedArgs = [];

    const handleTimeout = function() {
        'use strict';
        timeout = 0;
        if (shouldFire) {
            shouldFire = false;
            fire();
        }
    };

    const fire = function() {
        'use strict';
        timeout = window.setTimeout(handleTimeout, interval);
        // @ts-ignore
        const args = storedArgs;
        storedArgs = [];  // Avoid a space leak by clearing stored arguments.
        f.apply(opt_scope, args);
    };

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return /** @type {function(...?)} */ (function(var_args?: T) {
        'use strict';
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        storedArgs = arguments;
        if (!timeout) {
            fire();
        } else {
            shouldFire = true;
        }
    });
};
