import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
`;

export const More = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FetchBar = styled.div`
  width: 100%;
`;
