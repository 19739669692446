class ConfigurationService {
    private env: string;
    private POST_CAST_HOST: string;

    constructor() {
        this.env = process.env.REACT_APP_ENVIRONMENT || 'TEST';
        this.POST_CAST_HOST = process.env.REACT_APP_POST_CAST_HOST || 'http://localhost:5255';
    }

    public isProd = (): boolean => this.env === 'PROD';

    public isDev = (): boolean => /localhost/.test(window.location.href);

    public getPostCastHost = (): string => {
        return this.POST_CAST_HOST;
    };

    public image = (path: string): string => {
        return path ? `${this.getPostCastHost()}${path}` : '/static/default.svg';
    };

    public audio = (path: string): string => {
        return `https://storage.yandexcloud.net/acha-static/audio/${path}`;
    };
}

export default ConfigurationService;
