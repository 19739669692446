import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const Button = styled.button`
  background-color: #3b96ff;
  width: 96px;
  border: none;
  color: #ffffff;
  font: 500;
  font-size: 18px;
  border-radius: 12px;
  width: 120px;
  padding: 8px 16px;
`;

export const Img = styled.img`
  max-width: 200px;
`;
