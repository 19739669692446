import { Cell, Spinner } from '@telegram-apps/telegram-ui';
import React, { FC, useEffect, useRef } from 'react';

import Time from '@/lib/time';
import NavigatorService from '@/services/navigator-service';
import { Post } from '@/services/post-cast-service';

import * as UI from './ui';

import { getAnyDuration } from '../../../../domain/voice';


type ChannelPageProps = {
    posts: Post[];
    currentPost: number;
    currentTime: number;
    playing: boolean;
    playerPlaylist: string;
    playlistLoading: boolean;
    navigatorService: NavigatorService;
    onPostClick: (playlist: string, index: number) => void;
    onPlaylistLoad: (username: string) => void;
}

const ChannelPage: FC<ChannelPageProps> = ({
    posts,
    currentPost,
    currentTime,
    playing,
    playerPlaylist,
    playlistLoading,
    navigatorService,
    onPostClick,
    onPlaylistLoad,
}) => {
    const fetchBar = useRef<HTMLDivElement>(null);

    const username = navigatorService.getChannelUsername();
    const isCurrent = playerPlaylist === username;
    const isPlaying = playing && isCurrent;

    useEffect(() => {
        Telegram.WebApp.BackButton.show();
    }, [username]);

    // надо научиться делать пагинацию листая в обратную сторону - наверх
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        onPlaylistLoad(username);
                    }
                });
            },
            {
                root: null,
                threshold: 0.1,
            },
        );

        if (fetchBar.current) {
            observer.observe(fetchBar.current);
        }

        return () => {
            if (fetchBar.current) {
                observer.unobserve(fetchBar.current);
            }
        };
    }, [fetchBar.current]);

    return (
        <>
            {
                posts.map((post, index) => (
                    <React.Fragment key={post.title}>
                        <Cell
                            hovered={isCurrent && index === currentPost}
                            subhead={
                                <UI.ColoredDuration
                                    $active={isPlaying && (index === currentPost)}
                                    $selected={index === currentPost}
                                >
                                    {
                                        isPlaying && index === currentPost ?
                                            Time.displayFormat(currentTime) :
                                            Time.displayFormat(getAnyDuration(post.audio))
                                    }
                                </UI.ColoredDuration>
                            }
                            description={post.title}
                            onClick={() => onPostClick(username, index)}
                        />
                    </React.Fragment>
                ))
            }

            <UI.FetchBar ref={fetchBar} />

            {
                playlistLoading && (
                    <UI.SpinnerWrapper>
                        <Spinner size='m' />
                    </UI.SpinnerWrapper>
                )
            }
        </>
    );
};

export default ChannelPage;
