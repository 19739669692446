import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
    export interface DefaultTheme {
        colors: {
            primaryColor: string;
            secondaryTextColor: string;
            lightPrimaryColor: string;
            bodyBackgroundColor: string;
            bodyBackgroundColorRgb: string;
            backgroundColorTrue: string;
            backgroundColor: string;
            borderColor: string;
            scrollbarColor: string;
            sectionBoxShadowColor: string;
            menuBoxShadowColor: string;
            inputSearchBackgroundColor: string;
            inputSearchBorderColor: string;
            secondaryColor: string;
            warningColor: string;
            greenColor: string;
            avatarOnlineColor: string;
            chatlistStatusColor: string;
            chatlistPinnedColor: string;
            badgeTextColor: string;
            linkColor: string;
            rippleColor: string;
            skeletonColor: string;
            pollCircleColor: string;
            spoilerBackgroundColor: string;
            spoilerDraftBackgroundColor: string;
            monospaceTextColor: string;
            backdropOpacity: string;
            menuBackgroundColor: string;
            rlottieVectorFill: string;
            limitLineEmptyBackground: string;
            oldInputBackgroundColor: string;
            premiumColor: string;
            gcBackgroundColor: string;
            gcButtonLeaveColor: string;
            gcButtonUnmutedColor: string;
            gcButtonMutedColor: string;
            gcButtonHandColor: string;
            gcButtonUnmutedFillColor: string;
            gcButtonMutedFillColor: string;
            gcGreenColor: string;
            gcBlueColor: string;
            gcRedColor: string;
            gcPrimaryTextColor: string;
            gcSecondaryTextColor: string;
            gcGreenTextColor: string;
            gcBlueTextColor: string;
            gcRedTextColor: string;
        },
        zIndex: {
            under: number;
            start: number;
            header: number;
            popup: number;
        };
        isIos: () => boolean;
        isAndroid: () => boolean;
    }
}

const zIndex = {
    under: -1,
    start: 1,
    header: 2,
    popup: 3,
};

export const defaultTheme: DefaultTheme = {
    colors: {
        primaryColor: '#3390ec', // Equivalent to --gc-blue-text-color
        secondaryTextColor: '#707579', // Not directly provided, so a reasonable grey shade is used
        lightPrimaryColor: 'rgba(51, 144, 236, 0.08)',

        bodyBackgroundColor: '#fff',
        bodyBackgroundColorRgb: '255, 255, 255',
        backgroundColorTrue: '#f4f4f5',
        backgroundColor: '#f4f4f5',
        borderColor: '#dfe1e5',
        scrollbarColor: 'rgba(0, 0, 0, .2)',
        sectionBoxShadowColor: 'rgba(0, 0, 0, .06)',
        menuBoxShadowColor: 'rgba(0, 0, 0, .15)',
        inputSearchBackgroundColor: '#fff',
        inputSearchBorderColor: '#dfe1e5',
        secondaryColor: '#c4c9cc',
        warningColor: '#fed85a',
        greenColor: '#3ba748',
        avatarOnlineColor: '#3390ec',
        chatlistStatusColor: '#3390ec',
        chatlistPinnedColor: '#a2abb2',
        badgeTextColor: '#fff',
        linkColor: '#00488f',
        rippleColor: 'rgba(0, 0, 0, .08)',
        skeletonColor: 'rgba(0, 0, 0, .12)',
        pollCircleColor: '#dfe1e5',
        spoilerBackgroundColor: '#e3e5e8',
        spoilerDraftBackgroundColor: '#d9d9d9',
        monospaceTextColor: '#ff595a',
        backdropOpacity: '.85',
        menuBackgroundColor: 'rgba(255, 255, 255, .85)', // Assuming surface-color-rgb is white
        rlottieVectorFill: 'rgba(0, 0, 0, .08)',
        limitLineEmptyBackground: '#F1F3F5',
        oldInputBackgroundColor: '#F1F3F5',
        premiumColor: '#9275ff',
        gcBackgroundColor: '#212121',
        gcButtonLeaveColor: 'rgba(255, 89, 90, .3)',
        gcButtonUnmutedColor: 'rgba(1, 200, 80, .2)',
        gcButtonMutedColor: 'rgba(60, 135, 247, .2)',
        gcButtonHandColor: 'rgba(223, 68, 184, .2)',
        gcButtonUnmutedFillColor: '#195530',
        gcButtonMutedFillColor: '#3C87F7',
        gcGreenColor: '#195530',
        gcBlueColor: '#3C87F7',
        gcRedColor: '#643333',
        gcPrimaryTextColor: '#fff',
        gcSecondaryTextColor: '#aaaaaa',
        gcGreenTextColor: '#5CC85E',
        gcBlueTextColor: '#3390ec',
        gcRedTextColor: '#ff595a',
    },
    zIndex,
    isIos: () => window.Telegram.WebApp.platform === 'ios',
    isAndroid: () => window.Telegram.WebApp.platform === 'android',
};
