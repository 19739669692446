/*********************************
 * COPYPASTED https://github.com/lhz516/react-h5-audio-player/blob/master/src/index.tsx
 *********************************/

import React, {
    Component,
    cloneElement,
    isValidElement,
    createRef,
    ReactNode,
    CSSProperties,
    ReactElement,
    Key,
} from 'react';

import { Controls, MainWrapper } from '@/features/AudioPlayer/ui';
import Tgico from '@/shared/icons/tgico';

import { RHAP_UI, MAIN_LAYOUT, AUDIO_PRELOAD_ATTRIBUTE, TIME_FORMAT } from './constants';
import CurrentTime from './CurrentTime';
import Duration from './Duration';
import ProgressBar from './ProgressBar';
import { throttle, getMainLayoutClassName, getDisplayTimeBySeconds } from './utils';

type CustomUIModule = RHAP_UI | ReactElement
type CustomUIModules = CustomUIModule[]
type OnSeek = (audio: HTMLAudioElement, time: number) => Promise<void>

interface MSEPropsObject {
  onSeek: OnSeek
  onEcrypted?: (e: unknown) => void
  srcDuration: number
}

interface PlayerProps {
    audio: HTMLAudioElement | null;
    /**
    * HTML5 Audio tag autoPlay property
    */
    autoPlay?: boolean
    /**
    * Whether to play audio after src prop is changed
    */
    autoPlayAfterSrcChange?: boolean
    /**
    * custom classNames
    */
    className?: string
    /**
    * The time interval to trigger onListen
    */
    listenInterval?: number
    progressJumpStep?: number
    progressJumpSteps?: {
    backward?: number
    forward?: number
    }
    volumeJumpStep?: number
    loop?: boolean
    muted?: boolean
    crossOrigin?: React.AudioHTMLAttributes<HTMLAudioElement>['crossOrigin']
    mediaGroup?: string
    hasDefaultKeyBindings?: boolean
    onAbort?: (e: Event) => void
    onCanPlay?: (e: Event) => void
    onCanPlayThrough?: (e: Event) => void
    onEnded?: (e: Event) => void
    onPlaying?: (e: Event) => void
    onSeeking?: (e: Event) => void
    onSeeked?: (e: Event) => void
    onStalled?: (e: Event) => void
    onSuspend?: (e: Event) => void
    onLoadStart?: (e: Event) => void
    onLoadedMetaData?: (e: Event) => void
    onLoadedData?: (e: Event) => void
    onWaiting?: (e: Event) => void
    onEmptied?: (e: Event) => void
    onError?: (e: Event) => void
    onListen?: (e: Event) => void
    onVolumeChange?: (e: Event) => void
    onPause?: (e: Event) => void
    onPlay?: (e: Event) => void
    onClickPrevious?: (e: React.SyntheticEvent) => void
    onClickNext?: (e: React.SyntheticEvent) => void
    onRewindForward?: VoidFunction;
    onRewindBack?: VoidFunction;
    onPlayError?: (err: Error) => void
    onChangeCurrentTimeError?: () => void
    mse?: MSEPropsObject
    /**
    * HTML5 Audio tag preload property
    */
    preload?: AUDIO_PRELOAD_ATTRIBUTE
    /**
    * Pregress indicator refresh interval
    */
    progressUpdateInterval?: number
    /**
    * HTML5 Audio tag src property
    */
    src?: string
    defaultCurrentTime?: ReactNode
    defaultDuration?: ReactNode
    volume?: number
    showJumpControls?: boolean
    showSkipControls?: boolean
    showDownloadProgress?: boolean
    showFilledProgress?: boolean
    showFilledVolume?: boolean
    timeFormat?: TIME_FORMAT
    header?: ReactNode
    footer?: ReactNode
    customIcons?: CustomIcons
    layout?: MAIN_LAYOUT
    customProgressBarSection?: CustomUIModules
    timeProgressBarSection: CustomUIModules;
    customControlsSection?: CustomUIModules
    customAdditionalControls?: CustomUIModules
    customVolumeControls?: CustomUIModules
    i18nAriaLabels?: I18nAriaLabels
    children?: ReactNode
    style?: CSSProperties
}

interface CustomIcons {
  play?: ReactNode
  pause?: ReactNode
  rewind?: ReactNode
  forward?: ReactNode
  previous?: ReactNode
  next?: ReactNode
  loop?: ReactNode
  loopOff?: ReactNode
  volume?: ReactNode
  volumeMute?: ReactNode
}

interface I18nAriaLabels {
  player?: string
  progressControl?: string
  volumeControl?: string
  play?: string
  pause?: string
  rewind?: string
  forward?: string
  previous?: string
  next?: string
  loop?: string
  loopOff?: string
  volume?: string
  volumeMute?: string
}

class H5AudioPlayer extends Component<PlayerProps> {
    static defaultProps: PlayerProps = {
        audio: null,
        autoPlay: false,
        autoPlayAfterSrcChange: true,
        listenInterval: 1000,
        progressJumpStep: 5000,
        progressJumpSteps: {}, // define when removing progressJumpStep
        volumeJumpStep: 0.1,
        loop: false,
        muted: false,
        preload: 'auto',
        progressUpdateInterval: 20,
        defaultCurrentTime: '--:--',
        defaultDuration: '--:--',
        timeFormat: 'auto',
        volume: 1,
        className: '',
        showJumpControls: true,
        showSkipControls: false,
        showDownloadProgress: true,
        showFilledProgress: true,
        showFilledVolume: false,
        customIcons: {},
        customProgressBarSection: [RHAP_UI.PROGRESS_BAR],
        timeProgressBarSection: [RHAP_UI.CURRENT_TIME, RHAP_UI.DURATION],
        customControlsSection: [RHAP_UI.ADDITIONAL_CONTROLS, RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS],
        customAdditionalControls: [RHAP_UI.LOOP],
        customVolumeControls: [RHAP_UI.VOLUME],
        layout: 'stacked',
        hasDefaultKeyBindings: true,
        i18nAriaLabels: {
            player: 'Audio player',
            progressControl: 'Audio progress control',
            volumeControl: 'Volume control',
            play: 'Play',
            pause: 'Pause',
            rewind: 'Rewind',
            forward: 'Forward',
            previous: 'Previous',
            next: 'Skip',
            loop: 'Disable loop',
            loopOff: 'Enable loop',
            volume: 'Mute',
            volumeMute: 'Unmute',
        },
    };


    progressBar = createRef<HTMLDivElement>();

    container = createRef<HTMLDivElement>();

    // @ts-ignore
    lastVolume: number = this.props.volume; // To store the volume before clicking mute button

    listenTracker?: number; // Determine whether onListen event should be called continuously

    volumeAnimationTimer?: number;

    downloadProgressAnimationTimer?: number;

    togglePlay = (e: React.SyntheticEvent): void => {
        e.stopPropagation();
        const audio = this.props.audio;
        if (!audio) {
            return;
        }

        if ((audio.paused || audio.ended) && audio.src) {
            this.playAudioPromise();
        } else if (!audio.paused) {
            audio.pause();
        }
    };

    /**
   * Safely play audio
   *
   * Reference: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
   */
    playAudioPromise = (): void => {
        if (!this.props.audio) {
            return;
        }

        const playPromise = this.props.audio.play();
        // playPromise is null in IE 11
        if (playPromise) {
            playPromise.then(null).catch((err) => {
                const { onPlayError } = this.props;
                onPlayError && onPlayError(new Error(err));
            });
        } else {
            // Remove forceUpdate when stop supporting IE 11
            this.forceUpdate();
        }
    };

    isPlaying = (): boolean => {
        const audio = this.props.audio;
        if (!audio) {return false;}

        return !audio.paused && !audio.ended;
    };

    handlePlay = (e: Event): void => {
        this.forceUpdate();
        this.props.onPlay && this.props.onPlay(e);
    };

    handlePause = (e: Event): void => {
        if (!this.props.audio) {return;}
        this.forceUpdate();
        this.props.onPause && this.props.onPause(e);
    };

    handleEnded = (e: Event): void => {
        if (!this.props.audio) {return;}
        // Remove forceUpdate when stop supporting IE 11
        this.forceUpdate();
        this.props.onEnded && this.props.onEnded(e);
    };

    handleAbort = (e: Event): void => {
        this.props.onAbort && this.props.onAbort(e);
    };

    handleClickVolumeButton = (): void => {
        if (!this.props.audio) {
            return;
        }

        const audio = this.props.audio;
        if (audio.volume > 0) {
            this.lastVolume = audio.volume;
            audio.volume = 0;
        } else {
            audio.volume = this.lastVolume;
        }
    };

    handleMuteChange = (): void => {
        this.forceUpdate();
    };

    handleClickLoopButton = (): void => {
        if (!this.props.audio) {
            return;
        }

        this.props.audio.loop = !this.props.audio.loop;
        this.forceUpdate();
    };

    setJumpVolume = (volume: number): void => {
        if (!this.props.audio) {
            return;
        }

        let newVolume = this.props.audio.volume + volume;
        if (newVolume < 0) {
            newVolume = 0;
        } else if (newVolume > 1) {
            newVolume = 1;
        }

        this.props.audio.volume = newVolume;
    };

    handleKeyDown = (e: React.KeyboardEvent): void => {
        if (this.props.hasDefaultKeyBindings) {
            switch (e.key) {
                case ' ':
                    if (e.target === this.container.current || e.target === this.progressBar.current) {
                        e.preventDefault(); // Prevent scrolling page by pressing Space key
                        this.togglePlay(e);
                    }
                    break;
                case 'ArrowUp':
                    e.preventDefault(); // Prevent scrolling page by pressing arrow key
                    // @ts-ignore
                    this.setJumpVolume(this.props.volumeJumpStep);
                    break;
                case 'ArrowDown':
                    e.preventDefault(); // Prevent scrolling page by pressing arrow key
                    // @ts-ignore
                    this.setJumpVolume(-this.props.volumeJumpStep);
                    break;
                case 'l':
                    this.handleClickLoopButton();
                    break;
                case 'm':
                    this.handleClickVolumeButton();
                    break;
            }
        }
    };

    renderUIModules = (modules: CustomUIModules): ReactElement[] => {
        return modules.map((comp, i) => this.renderUIModule(comp, i));
    };

    renderUIModule = (comp: CustomUIModule, key: Key): ReactElement => {
        const {
            defaultCurrentTime,
            progressUpdateInterval,
            showDownloadProgress,
            showFilledProgress,
            // showFilledVolume,
            defaultDuration,
            // customIcons,
            // showSkipControls,
            onRewindBack,
            onRewindForward,
            onClickPrevious,
            onClickNext,
            onChangeCurrentTimeError,
            // showJumpControls,
            customAdditionalControls,
            // customVolumeControls,
            // muted,
            timeFormat,
            // volume: volumeProp,
            // loop: loopProp,
            mse,
            i18nAriaLabels,
        } = this.props;

        switch (comp) {
            case RHAP_UI.CURRENT_TIME:
                return (
                    <div
                        key={key}
                        id='rhap_current-time'
                        className='rhap_time rhap_current-time'
                    >
                        <CurrentTime
                            // @ts-ignore
                            audio={this.props.audio}
                            isLeftTime={false}
                            defaultCurrentTime={defaultCurrentTime}
                            // @ts-ignore
                            timeFormat={timeFormat}
                        />
                    </div>
                );
            case RHAP_UI.CURRENT_LEFT_TIME:
                return (
                    <div
                        key={key}
                        id='rhap_current-left-time'
                        className='rhap_time rhap_current-left-time'
                    >
                        <CurrentTime
                            // @ts-ignore
                            audio={this.props.audio}
                            isLeftTime={true}
                            defaultCurrentTime={defaultCurrentTime}
                            // @ts-ignore
                            timeFormat={timeFormat}
                        />
                    </div>
                );
            case RHAP_UI.PROGRESS_BAR:
                return (
                    <ProgressBar
                        key={key}
                        ref={this.progressBar}
                        // @ts-ignore
                        audio={this.props.audio}
                        // @ts-ignore
                        progressUpdateInterval={progressUpdateInterval}
                        // @ts-ignore
                        showDownloadProgress={showDownloadProgress}
                        // @ts-ignore
                        showFilledProgress={showFilledProgress}
                        // @ts-ignore
                        onSeek={mse ? mse.onSeek : null}
                        onChangeCurrentTimeError={onChangeCurrentTimeError}

                        // eslint-disable-next-line react/jsx-props-no-multi-spaces
                        srcDuration={mse ? mse.srcDuration : undefined}
                        // @ts-ignore
                        i18nProgressBar={i18nAriaLabels.progressControl}
                    />
                );
            case RHAP_UI.DURATION:
                return (
                    <div
                        key={key}
                        className='rhap_time rhap_total-time'
                    >
                        {
                            mse && mse.srcDuration ? (
                                // @ts-ignore
                                getDisplayTimeBySeconds(mse.srcDuration, mse.srcDuration, this.props.timeFormat)
                            ) : (
                                <Duration
                                    // @ts-ignore
                                    audio={this.props.audio}
                                    defaultDuration={defaultDuration}
                                    // @ts-ignore
                                    timeFormat={timeFormat}
                                />
                            )
                        }
                    </div>
                );
            case RHAP_UI.ADDITIONAL_CONTROLS:
                return (
                    <div
                        key={key}
                        className='rhap_additional-controls'
                    >
                        {/*@ts-ignore*/}
                        {this.renderUIModules(customAdditionalControls)}
                    </div>
                );
            case RHAP_UI.MAIN_CONTROLS: {
                const isPlaying = this.isPlaying();

                return (
                    <Controls key={key}>
                        <Tgico
                            size={2.5}
                            type='rewindBack'
                            onClick={onRewindBack}
                        />

                        <Tgico
                            size={3}
                            type='previous'
                            onClick={onClickPrevious}
                        />

                        {
                            isPlaying ? (
                                <Tgico
                                    size={4}
                                    type='play'
                                    onClick={this.togglePlay}
                                />
                            ) : (
                                <Tgico
                                    size={4}
                                    type='pause'
                                    onClick={this.togglePlay}
                                />
                            )
                        }

                        <Tgico
                            size={3}
                            type='next'
                            onClick={onClickNext}
                        />

                        <Tgico
                            size={2.5}
                            type='rewindForward'
                            onClick={onRewindForward}
                        />
                    </Controls>
                );
            }
            case RHAP_UI.VOLUME_CONTROLS:
                return (
                    <div
                        key={key}
                        className='rhap_volume-controls'
                    >

                    </div>
                );
            default:
                if (!isValidElement(comp)) {
                    // @ts-ignore
                    return null;
                }

                return comp.key ? comp : cloneElement(comp as ReactElement, { key });
        }
    };

    componentDidMount(): void {
    // force update to pass this.props.audio to child components
        this.forceUpdate();
        // audio player object
        if (!this.props.audio) {
            return;
        }

        const audio = this.props.audio;

        if (this.props.muted) {
            audio.volume = 0;
        } else {
            audio.volume = this.lastVolume;
        }

        audio.addEventListener('error', (e) => {
            this.props.onError && this.props.onError(e);
        });

        // When enough of the file has downloaded to start playing
        audio.addEventListener('canplay', (e) => {
            this.props.onCanPlay && this.props.onCanPlay(e);
        });

        // When enough of the file has downloaded to play the entire file
        audio.addEventListener('canplaythrough', (e) => {
            this.props.onCanPlayThrough && this.props.onCanPlayThrough(e);
        });

        // When audio play starts
        audio.addEventListener('play', this.handlePlay);

        // When unloading the audio player (switching to another src)
        audio.addEventListener('abort', this.handleAbort);

        // When the file has finished playing to the end
        audio.addEventListener('ended', this.handleEnded);

        // When the media has enough data to start playing, after the play event, but also when recovering from being
        // stalled, when looping media restarts, and after seeked, if it was playing before seeking.
        audio.addEventListener('playing', (e) => {
            this.props.onPlaying && this.props.onPlaying(e);
        });

        // When a seek operation begins
        audio.addEventListener('seeking', (e) => {
            this.props.onSeeking && this.props.onSeeking(e);
        });

        // when a seek operation completes
        audio.addEventListener('seeked', (e) => {
            this.props.onSeeked && this.props.onSeeked(e);
        });

        // eslint-disable-next-line max-len
        // when the requested operation (such as playback) is delayed pending the completion of another operation (such as
        // a seek).
        audio.addEventListener('waiting', (e) => {
            this.props.onWaiting && this.props.onWaiting(e);
        });

        // eslint-disable-next-line max-len
        // The media has become empty; for example, this event is sent if the media has already been loaded (or partially
        // loaded), and the load() method is called to reload it.
        audio.addEventListener('emptied', (e) => {
            this.props.onEmptied && this.props.onEmptied(e);
        });

        // when the user agent is trying to fetch media data, but data is unexpectedly not forthcoming
        audio.addEventListener('stalled', (e) => {
            this.props.onStalled && this.props.onStalled(e);
        });

        // eslint-disable-next-line max-len
        // when loading of the media is suspended; this may happen either because the download has completed or because it
        // has been paused for any other reason
        audio.addEventListener('suspend', (e) => {
            this.props.onSuspend && this.props.onSuspend(e);
        });

        //  when loading of the media begins
        audio.addEventListener('loadstart', (e) => {
            this.props.onLoadStart && this.props.onLoadStart(e);
        });

        // when media's metadata has finished loading; all attributes now contain as much useful information as they're
        // going to
        audio.addEventListener('loadedmetadata', (e) => {
            this.props.onLoadedMetaData && this.props.onLoadedMetaData(e);
        });

        // when the first frame of the media has finished loading.
        audio.addEventListener('loadeddata', (e) => {
            this.props.onLoadedData && this.props.onLoadedData(e);
        });

        // When the user pauses playback
        audio.addEventListener('pause', this.handlePause);

        audio.addEventListener(
            'timeupdate',
            throttle((e) => {
                this.props.onListen && this.props.onListen(e);
            // @ts-ignore
            }, this.props.listenInterval)
        );

        audio.addEventListener('volumechange', (e) => {
            this.props.onVolumeChange && this.props.onVolumeChange(e);
        });

        audio.addEventListener('encrypted', (e) => {
            const { mse } = this.props;
            mse && mse.onEcrypted && mse.onEcrypted(e);
        });
    }

    componentDidUpdate(prevProps: PlayerProps): void {
        const { src, autoPlayAfterSrcChange } = this.props;
        if (prevProps.src !== src) {
            if (autoPlayAfterSrcChange) {
                this.playAudioPromise();
            } else {
                // Updating pause icon to play icon
                this.forceUpdate();
            }
        }
    }

    render(): ReactNode {
        const {
            className,
            // src,
            loop: loopProp,
            // preload,
            // autoPlay,
            // crossOrigin,
            // mediaGroup,
            // header,
            footer,
            layout,
            customProgressBarSection,
            timeProgressBarSection,
            customControlsSection,
            children,
            style,
            i18nAriaLabels,
        } = this.props;
        const loop = this.props.audio ? this.props.audio.loop : loopProp;
        const loopClass = loop ? 'rhap_loop--on' : 'rhap_loop--off';
        const isPlayingClass = this.isPlaying() ? 'rhap_play-status--playing' : 'rhap_play-status--paused';

        return (
        /* We want the container to catch bubbled events */
            <MainWrapper
                role='group'
                tabIndex={0}
                // @ts-ignore
                aria-label={i18nAriaLabels.player}
                className={`rhap_container ${loopClass} ${isPlayingClass} ${className}`}
                onKeyDown={this.handleKeyDown}
                ref={this.container}
                style={style}
            >
                {children}

                {/*@ts-ignore*/}
                <div className={`rhap_main ${getMainLayoutClassName(layout)}`}>
                    {/*@ts-ignore*/}
                    <div className='rhap_progress-section'>{this.renderUIModules(customProgressBarSection)}</div>
                    <div className='rhap_time-progress-section'>{this.renderUIModules(timeProgressBarSection)}</div>
                    {/*@ts-ignore*/}
                    <div className='rhap_controls-section'>{this.renderUIModules(customControlsSection)}</div>
                </div>

                {footer ? <div className='rhap_footer'>{footer}</div> : null}
            </MainWrapper>
        );
    }
}

export default H5AudioPlayer;
export { RHAP_UI };    export type { OnSeek };

