import EventEmitter from '@/lib/subscriber';

class LogsService extends EventEmitter<{ 'onLogsUpdate': string[] }>{
    private messages: string[];

    constructor() {
        super();

        this.messages = [];
    }
    public write = (msg: string): void => {
        const message = `${msg}:${performance.now().toFixed()}`;
        this.messages.push(message);
        this.dispatch('onLogsUpdate', this.messages);

        console.info(message);
    };

    public captureMessage(message: string): void {
        console.warn(message);
    }

    public getLogs = (): string[] => {
        return this.messages;
    };

    public writeInitialLogLine = () => {
        this.write([
            `ua_${window.navigator.userAgent}`,
            `${navigator?.languages?.toString()}`
        ].join(''));
    };
}

export default LogsService;
