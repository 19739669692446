import styled, { css } from 'styled-components';

export const Outer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 28px;
  padding: 0 12px;

  ${props => props.theme.isIos() && css`
    bottom: 28px;
  `}
  left: 0;
  width: 100%;
`;

export const Wrapper = styled.div`
  border-radius: 12px;
  width: 100%;
  background-color: var(--tg-theme-bg-color);

  box-shadow: 1px -2px 16px 1px rgba(0, 0, 0, .1);

  background-color: #ffffff;
  @media (prefers-color-scheme: dark) {
    background-color: #212121;
  }
`;

export const Head = styled.div`
  padding: 5px 0;
`;

export const Cell = styled.div`
  padding: 0 6px;
  
  display: flex;
  justify-content: space-between;
`;

export const PlayerContent = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1 1 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PlayerTitle = styled.span`
  display: flex;
  margin-inline-start: 12px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.secondaryTextColor};
`;

export const PlayerControls = styled.div`
  display: flex;
`;
