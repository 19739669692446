class Time {
    // 1 -> 01
    public static zz = (t: number | string): string =>
        t.toString().length === 1 ? `0${t}` : t.toString();

    // 1 -> 001
    public static zzz = (t: number | string): string => {
        if (t.toString().length < 3) {
            return `0${Time.zz(t)}`;
        }

        return t.toString();
    };

    public static displayFormat = (s: number): string => {
        const ms = s * 1000;
        const d = new Date(ms);
        const hours = Time.zz(d.getUTCHours());
        const min = Time.zz(d.getMinutes());
        const sec = Time.zz(d.getSeconds());

        if (d.getUTCHours() > 0) {
            return `${hours}:${min}:${sec}`;
        }

        return `${min}:${sec}`;
    };
}

export default Time;
