import { Avatar } from '@telegram-apps/telegram-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Center } from '@/shared/ui/layout/center';

import * as UI from './ui';

type LandingProps = {
    href: string;
}

const Landing: FC<LandingProps> = ({ href }) => {
    const { t } = useTranslation();

    return (
        <Center>
            <UI.Link href={href}>
                <UI.Wrapper>
                    <Avatar
                        size={96}
                        src='/static/default.svg'
                    />

                    <UI.Button>{t('features.Landing.redirectButtonCaption')}</UI.Button>
                    <UI.Img src='/static/qr.jpg' />
                </UI.Wrapper>
            </UI.Link>
        </Center>
    );
};

export default Landing;
