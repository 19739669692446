import i18n from 'i18next';

class Resources {
    static SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';

    static getDir = (): 'ltr' | 'rtl' => i18n.dir();
    static isRtl = (): boolean => Resources.getDir() === 'rtl';

    static files = {

    };
}

export default Resources;
