import { Avatar } from '@telegram-apps/telegram-ui';
import { FC, useCallback } from 'react';

import Tgico from '@/shared/icons/tgico';

import * as UI from './ui';

type MiniPlayerProps = {
    title: string;
    image: string;
    playing: boolean;
    onClick: VoidFunction;
    onTogglePlay: VoidFunction;
    onRewindForward: VoidFunction;
}
const MiniPlayer: FC<MiniPlayerProps> = ({
    title,
    playing,
    image,
    onClick,
    onTogglePlay,
    onRewindForward,
}) => {
    const handlePlayClick = useCallback((evt: { stopPropagation: () => void; }) => {
        evt.stopPropagation();

        onTogglePlay();
    }, []);

    const handleRewindClick = useCallback((evt: { stopPropagation: () => void; }) => {
        evt.stopPropagation();

        onRewindForward();
    }, []);

    return (
        <UI.Outer>
            <UI.Wrapper>
                <UI.Head>
                    <UI.Cell onClick={onClick}>
                        <UI.PlayerContent>
                            <Avatar
                                size={40}
                                src={image}
                            />

                            <UI.PlayerTitle>
                                {title}
                            </UI.PlayerTitle>
                        </UI.PlayerContent>

                        <UI.PlayerControls>
                            <Tgico
                                size={1.5}
                                type={playing ? 'play' : 'pause'}
                                onClick={handlePlayClick}
                            />

                            <Tgico
                                size={1.5}
                                type='rewindForward'
                                onClick={handleRewindClick}
                            />

                        </UI.PlayerControls>
                    </UI.Cell>
                </UI.Head>
            </UI.Wrapper>
        </UI.Outer>
    );
};

export default MiniPlayer;

