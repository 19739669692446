import { Spinner } from '@telegram-apps/telegram-ui';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import Landing from '@/features/Landing';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import PostCastService, { InitData } from '@/services/post-cast-service';
import { Center } from '@/shared/ui/layout/center';

import PlayerApp from './player-app';

type AppProps = {
    logsService: LogsService;
    configurationService: ConfigurationService;
}
const App: FC<AppProps> = ({
    logsService,
    configurationService
}) => {
    const [loading, setLoading] = useState(true);
    const [initData, setInitData] = useState<InitData | null>(null);

    const [navigatorService] = useState(new NavigatorService(
        useNavigate(),
        useLocation,
    ));

    const [postCastService] = useState(new PostCastService(
        logsService,
        configurationService,
        navigatorService,
    ));

    const displayedPlaylist = navigatorService.getChannelUsername();

    useEffect(() => {
        postCastService.onInit(async (info) => {
            setInitData(info);
            setLoading(false);
        });

        // Scenario when user comes from telegram miniapp direct link
        // https://core.telegram.org/bots/webapps#direct-link-mini-apps
        const startupChannelParams = navigatorService.getMiniAppStartupParam();
        if (startupChannelParams !== null) {
            const [playlistFromParams, postToPlayFromParams] = startupChannelParams;
            postCastService.getInitData(playlistFromParams, postToPlayFromParams);
        } else {
            postCastService.getInitData(displayedPlaylist);
        }

        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        postCastService.event({
            eventName: 'user_bot_started'
        });
    }, []);

    if (window.Telegram.WebApp.platform === 'unknown' && configurationService.isProd()) {
        window.open('https://auchappbot.t.me');

        return (
            <Landing href='https://auchappbot.t.me' />
        );
    }

    if (!initData || loading) {
        return (
            <Center>
                <Spinner size='l' />
            </Center>
        );
    }

    return (
        <PlayerApp
            displayedPlaylist={displayedPlaylist}
            initData={initData}
            postCastService={postCastService}
            navigatorService={navigatorService}
            logsService={logsService}
            configurationService={configurationService}
        />
    );
};

export default App;
