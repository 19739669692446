import { FC } from 'react';

import * as UI from './ui';

type CellProps = {
    before?: React.ReactElement;
    head?: React.ReactElement | string;
    body?: React.ReactElement | string;
    content?: React.ReactElement;
    after?: React.ReactElement;
    onClick: VoidFunction;
}

const Cell: FC<CellProps> = ({
    before,
    head,
    body,
    after,
    onClick,
}) => {
    return (
        <UI.Wrapper onClick={onClick}>
            <UI.Left>
                <UI.Before>
                    { before }
                </UI.Before>

                <UI.Content>
                    <UI.Head>
                        {head}
                    </UI.Head>

                    <UI.Body>
                        {body}
                    </UI.Body>
                </UI.Content>
            </UI.Left>

            <UI.After>
                { after }
            </UI.After>
        </UI.Wrapper>
    );
};

export default Cell;
