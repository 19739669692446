import styled, { css } from 'styled-components';

export const ColoredDuration = styled.div<{ $active: boolean; $selected: boolean}>`
  ${props => props.$selected && css`
      font-weight: 600;
    `}
  
    ${props => props.$active && css`
      color: ${props.theme.colors.primaryColor}
    `}
`;

export const FetchBar = styled.div`
  width: 100%;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
