import { FC } from 'react';

import * as UI from './ui';

import Resources from '../../../../resources';

type LayoutProps = {
    children: React.ReactNode;
}

const AppLayout: FC<LayoutProps> = ({ children }) => {
    return (
        <UI.Wrapper dir={Resources.getDir()}>
            {children}
        </UI.Wrapper>
    );
};

export default AppLayout;
