import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANG } from '@/lib/locale';

const initializeI18N = () => {
    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            debug: false,
            load: 'languageOnly', // 'en-US' ⇒ 'en'
            detection: {
                order: ['querystring', 'navigator'],
            },
            fallbackLng: DEFAULT_LANG,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            }
        });
};

export default initializeI18N;
